"use client";
import React from "react";
import useMedia from "@/hooks/useMedia";
import { NAV_LIST_ITEM } from "@/types/LandingNavList";
import { NavLink } from "@/components/landing/Header/Header";
const Nav = () => {
  const isDesktop = useMedia(1275, "min");
  return (
    isDesktop && (
      <nav className="*:text-button desktop:*:text-buttonMd *:uppercase *:font-bold hidden laptop:block ">
        <ul className="flex items-center gap-[32px]">
          {NAV_LIST_ITEM.map((nav, index) => (
            <NavLink key={index} title={nav.title} href={nav.href} />
          ))}
        </ul>
      </nav>
    )
  );
};
export default Nav;
