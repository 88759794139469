'use client'
import React from 'react';
import HeaderProcessButtons from "@/components/landing/Header/HeaderProcessButtons";
import MobileNav from "@/components/landing/Header/MobileNav";
import useMedia from "@/hooks/useMedia";

const MobileHeader = () => {
    const isLaptop = useMedia(1275);
    const isMobile = useMedia(768);
    return (
        <div className="flex items-center gap-3">
            {!isMobile && <HeaderProcessButtons className="hidden tablet:flex"/>}
            {isLaptop && <MobileNav/>}
        </div>
    );
};

export default MobileHeader;