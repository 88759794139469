import useSWR from "swr";
import { AxiosError } from "axios";
import Base from "@/models/base/Base";
import { fetcher } from "@/utils/fetcher";
import { SiteSetting } from "@/models/SiteSetting";

type Props = {
  key?: string;
  data?: any;
};

const BASE_URL = "/v1/home/site-setting-landing-page";
export const useGetLandingSiteSettingsService = (props?: Props) => {
  const { data, isLoading, error, isValidating, mutate } = useSWR<
    Base<{ data: SiteSetting[] }>,
    AxiosError
  >(
    props?.key + BASE_URL,
    () =>
      fetcher<Base<{ data: SiteSetting[] }>>(BASE_URL, "POST", {
        sitesetting: [
          "landing_hero_banner",
          "banner_card_left",
          "banner_card_right",
          "novacent_banner_big",
          "novacent_banner_small_1",
          "novacent_banner_small_2",
          "novacent_banner_small_3",
          "novacent_banner_small_4",
          "landing_page_services",
          "landing_user_comments",
          "landing_faqs",
          "auth_banner",
        ],
      }),
    {
      revalidateOnFocus: false,
    }
  );
  return { data, isLoading, error, isValidating, mutate };
};
