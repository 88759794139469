"use client";
import React from "react";
import Text from "@/components/ui/Text";
import { ShoppingBasket, User1 } from "@/components/icons";
import LinkButton from "@/components/ui/Buttons/LinkButton";
import { useGetLandingSiteSettingsService } from "@/services/Shareds/Settings/useGetLandingSiteSettings";
import Image from "next/image";
import LazyImage from "@/components/ui/LazyImage";
import Button from "@/components/ui/Buttons/Button";
import { useRouter } from "next/navigation";

const Hero = () => {
  const { data, isLoading } = useGetLandingSiteSettingsService();
  const hero = data?.responseData?.data?.find(
    (item) => item.description === "landing_hero_banner"
  )?.image_url;
  const router = useRouter();

  return (
    <div
      className={`w-full min-h-[258px] tablet:min-h-[352px] desktop:min-h-[460px] flex p-2 mobile:p-[60px] items-center gap-[10px] relative`}
    >
      {isLoading ? (
        <div className="w-full h-full inset-0 absolute left-0 top-0 -z-10 bg-slate-500/20 animate-pulse" />
      ) : (
        <LazyImage
          src={hero || ""}
          alt="hero-bg"
          width={2000}
          height={600}
          className="w-full h-full inset-0 absolute left-0 top-0 -z-10 object-cover object-center"
          quality={100}
        />
      )}
      <div className="min-w-[149px] flex flex-col items-start gap-5 laptop:gap-[28px] container">
        <div className="flex flex-col gap-2 items-start justify-between">
          <Text className="text-extended_neutral-N0" variant="h1" as="h1">
            Novadan <br /> Artık Yeni Yüzüyle
          </Text>
          <Text className="text-extended_neutral-N0" variant="paragraph" as="p">
            Yeni Arayüzümüz ile
            <br /> Novadanı keşfedin.
          </Text>
          {/* <Text className="text-[#F05A29]" variant="h4" as="h4">
            128,00 TL
          </Text>*/}
        </div>
        <Button
          endIcon={<User1 />}
          color="success"
          size="small"
          onClick={() => router.push("/login")}
        >
          Hemen Giriş Yap
        </Button>
      </div>
    </div>
  );
};

export default Hero;
