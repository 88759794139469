"use client";
import React, { useState } from "react";
import Image from "next/image";
import { Loading } from "@/components/icons";
import { ImageProps } from "next/dist/shared/lib/get-img-props";

interface Props extends ImageProps {
  fallback?: string;
}

const LazyImage = (props: Props) => {
  const {
    fallback = "/images/nophoto1.jpg",
    loading,
    onLoad,
    src,
    ...otherProps
  } = props;
  const [loaded, setLoaded] = useState(true);
  const [fallBacksrc, setFallbackSrc] = useState(src);
  return (
    <>
      <Image
        loading="lazy"
        onLoad={() => setLoaded(false)}
        onError={() => setFallbackSrc(fallback)}
        src={fallBacksrc}
        {...otherProps}
      />
      {loaded && (
        <div className="w-full h-full flex justify-center items-center absolute top-0 left-0">
          <Loading className="animate-spin" size={40} />
        </div>
      )}
    </>
  );
};

export default LazyImage;
