import React from "react";
import Button from "@/components/ui/Buttons/Button";
import { User5 } from "@/components/icons";
import LinkButton from "@/components/ui/Buttons/LinkButton";
import { useRouter } from "next/navigation";
type Props = {
  className?: string;
};
const HeaderProcessButtons = (props: Props) => {
  const { className = "" } = props;
  const router = useRouter();
  return (
    <div className={` items-center gap-3 ${className}`}>
      <Button
        onClick={() => router.push("/register")}
        className="flex-auto laptop:flex-none"
        variant="secondary"
      >
        Üye Ol
      </Button>
      <Button
        onClick={() => router.push("/login")}
        className="flex-auto laptop:flex-none"
        startIcon={<User5 size={20} />}
        color="default"
      >
        Giriş Yap
      </Button>
    </div>
  );
};

export default HeaderProcessButtons;
