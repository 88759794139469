'use client'
import React, {useState} from 'react';
import {BottomSheet} from "react-spring-bottom-sheet";
import Button from "@/components/ui/Buttons/Button";
import {BurgerIconLines} from "@/components/icons";

import 'react-spring-bottom-sheet/dist/style.css'
import Link from "next/link";
import HeaderProcessButtons from "@/components/landing/Header/HeaderProcessButtons";
import {NAV_LIST_ITEM} from "@/types/LandingNavList";
import Sheet from "@/components/shareds/Sheet/Sheet";
import Logo from "@/components/shareds/Logo";
import useMedia from "@/hooks/useMedia";

const MobileNav = () => {
    const [isOpen,setIsOpen] = useState(false);
    const isTablet = useMedia(768,"max");
    const isLaptop = useMedia(769, "min");

    return (
        <>
            <Button variant="icon" className="!bg-extended_neutral-N100 !text-black" onClick={() => setIsOpen(!isOpen)}><BurgerIconLines /></Button>
            {isTablet &&  <BottomSheet open={isOpen} onDismiss={() => setIsOpen(false)} footer={<HeaderProcessButtons className="w-full flex tablet:hidden" />} >
                <div className="flex flex-col gap-component-y items-center py-5 px-3 w-full">
                    <nav className="*:text-buttonMd *:uppercase *:font-bold w-full ">
                        <ul className=" flex flex-col items-center gap-component-y">
                            {NAV_LIST_ITEM.map((nav, index) => <li key={index}><Link href={nav.href}>{nav.title}</Link></li>)}
                        </ul>
                    </nav>
                </div>
            </BottomSheet>}
            {isLaptop && <Sheet isOpen={isOpen} setIsOpen={setIsOpen}>
                <Sheet.Header><Logo /></Sheet.Header>
                <Sheet.Content>
                    <nav className="*:text-buttonLg *:uppercase *:font-bold w-full">
                        <ul className="flex flex-col items-start gap-component-y">
                            {NAV_LIST_ITEM.map((nav, index) => <li key={index}><Link href={nav.href}>{nav.title}</Link></li>)}
                        </ul>
                    </nav>
                </Sheet.Content>
                <Sheet.Footer>
                    {({close}) => (
                        <div className="flex justify-end gap-2"><Button color="danger"
                                                                        onClick={close}>Kapat</Button><Button
                            color="success">Kaydet</Button></div>)}
                </Sheet.Footer>
            </Sheet>}
        </>

    );
};

export default MobileNav;