export const NAV_LIST_ITEM = [
  { title: "Fırsatlar Dünyası", href: "#popular-products" },
  { title: "Novacent Nedir", href: "#novacent-store" },
  { title: "Neden Novadan", href: "#why-us" },
  { title: "Hizmetlerimiz", href: "#user-comments" },
  { title: "SSS", href: "#faq" },
];

const asdasda = [
  {
    image: "",
    video: "UsQEQSO3czc",
    seller_name: "Namık Korkmaz",
    avatar: "",
    seller_rating: 5,
  },
  {
    image: "",
    video: "nBGb4M4xgHI",
    seller_name: "Meral Şahin",
    avatar: "",
    seller_rating: 4,
  },
  {
    image: "",
    video: "9XottohjjtA",
    seller_name: "Kamil Güdük",
    avatar: "",
    seller_rating: 5,
  },
  {
    image: "",
    video: "65ljATHrUCQ",
    seller_name: "Berrin Türker",
    avatar: "",
    seller_rating: 4,
  },
];
