import React from 'react';
import Logo from "@/components/shareds/Logo";
import Nav from "@/components/landing/Header/Nav";
import MobileHeader from "@/components/landing/Header/MobileHeader";
import dynamic from "next/dynamic";
export  const NavLink = dynamic(() => import('./NavItem'), {
    ssr: false, loading: loadingProps => <span className="bg-slate-500/10 w-16 h-6 animate-pulse"></span>
});
const Header = () => {
    return (
        <div className="bg-white sticky top-0 z-[99999]">
          <div className="container flex justify-between items-center py-page-y-lg">
              <Logo />
              <Nav />
              <MobileHeader />
          </div>
        </div>
    );
};
export default Header;